/**
 * @file Anything that can run on every page.
 */

import debounce from 'just-debounce-it';

/**
 * Global functions.
 *
 * Runs component js and any global tasks.
 */
const vGlobal = () => {
	import('../components/header/header').then(({ default: vHeader }) => {
		vHeader();
	});
	import('../components/mobile-menu/mobile-menu').then(
		({ default: vMobileMenu }) => {
			vMobileMenu();
		},
	);
	import('../components/main-menu/main-menu').then(({ default: vMainMenu }) => {
		vMainMenu();
	});
	import('../components/footer/footer').then(({ default: vFooter }) => {
		vFooter();
	});

	if (
		document.querySelector('.v-social-share') ||
		document.querySelector('.v-social-cta')
	) {
		import('./partials/social-sharing').then(({ default: vSocialSharing }) => {
			vSocialSharing();
		});

		import('../components/share-modal/share-modal').then(
			({ default: vShareModal }) => {
				vShareModal();
			},
		);
	}

	if (document.querySelector('.v-secondary-nav')) {
		import('../components/secondary-nav/secondary-nav').then(
			({ default: vSecondaryNav }) => {
				vSecondaryNav();
			},
		);
	}

	if (document.querySelectorAll('.v-inline-youtube').length) {
		import('../components/inline-youtube/inline-youtube').then(
			({ default: vInlineYoutube }) => {
				vInlineYoutube();
			},
		);
	}

	/* if (document.querySelector('.v-post-wrap')) {
		import('../components/post-wrap/post-wrap').then(
			({ default: vPostWrap }) => {
				vPostWrap();
			},
		);
	} */

	if (document.querySelector('.v-faqs')) {
		import('../components/faqs/faqs').then(({ default: vFaqs }) => {
			vFaqs();
		});
	}

	if (document.querySelector('.v-member-cards')) {
		import('../components/member-cards/member-cards').then(
			({ default: vMemberCards }) => {
				vMemberCards();
			},
		);
	}

	if (document.querySelectorAll('.v-image-gallery').length) {
		import('../components/image-gallery/image-gallery').then(
			({ default: vImageGallery }) => {
				vImageGallery();
			},
		);
	}

	if (document.querySelectorAll('.facetwp-facet').length) {
		import('../components/facet-filters/facet-filters').then(
			({ default: vFacetFilters }) => {
				vFacetFilters();
			},
		);
	}

	if (document.querySelector('.v-post-grid')) {
		import('../components/post-grid/post-grid').then(
			({ default: vPostGrid }) => {
				vPostGrid();
			},
		);
	}

	if (document.querySelectorAll('.v-lightbox-trigger').length) {
		import('./partials/lightbox').then(({ default: vLightbox }) => {
			vLightbox();
		});
	}

	import('../components/dropdown/dropdown').then(({ default: vDropdown }) => {
		vDropdown();
	});

	import('../components/footer-newsletter/footer-newsletter').then(
		({ default: vFooterNewsletter }) => {
			vFooterNewsletter();
		},
	);

	if (document.querySelector('.v-alert-bar')) {
		import('../components/alert-bar/alert-bar').then(
			({ default: vAlertBar }) => {
				vAlertBar();
			},
		);
	}

	if (document.querySelector('.v-popup-alert')) {
		import('../components/popup-alert/popup-alert').then(
			({ default: vPopupAlert }) => {
				vPopupAlert();
			},
		);
	}

	/**
	 * Set scrollbar width.
	 */
	const setScrollbarWidth = () => {
		document.body.style.setProperty(
			'--scrollbar-width',
			`${window.innerWidth - document.documentElement.clientWidth}px`,
		);
	};
	setScrollbarWidth();
	window.addEventListener('resize', debounce(setScrollbarWidth, 100));

	const selects = document.querySelectorAll('select');
	[...selects].forEach((select) => {
		const selectParent = select.parentNode;
		if (selectParent.classList.contains('v-form__select')) {
			return;
		}

		const wrapper = document.createElement('span');
		const arrow = document.createElement('span');
		wrapper.classList.add('v-form__select');
		arrow.classList.add('v-form__select-arrow');
		selectParent.insertBefore(wrapper, select);
		wrapper.appendChild(select);
		wrapper.appendChild(arrow);
		select.classList.add('v-form__select-field');
	});

	const checkboxes = document.querySelectorAll('[type="checkbox"]');
	[...checkboxes].forEach((checkbox) => {
		const checkboxParent = checkbox.parentNode;
		if (checkboxParent.classList.contains('v-form__checkbox-label')) {
			return;
		}

		const box = document.createElement('span');
		box.classList.add('v-form__checkbox');
		checkboxParent.insertBefore(box, checkbox.nextSibling);
		checkbox.classList.add('v-form__checkbox-original');
		checkboxParent.classList.add('v-form__checkbox-label');
	});

	/**
	 * Global Window Load.
	 *
	 * Runs polyfills on page load.
	 */
	async function onWindowLoad() {
		const body = document.querySelector('body');
		if (!body.classList.contains('admin-bar')) {
			const { listen } = await import('quicklink');

			listen({
				ignores: [
					(url) => {
						const cleanUrl = url.replace(/#.*/, '');
						return cleanUrl === window.location.href;
					},
					/.*\/wp-admin\/.*/,
				],
			});
		}
	}

	if (document.readyState === 'complete') {
		onWindowLoad();
	} else {
		window.addEventListener('load', onWindowLoad);
	}
};

if (document.readyState !== 'loading') {
	vGlobal();
} else {
	document.addEventListener('DOMContentLoaded', vGlobal);
}
